/* eslint-disable max-lines */
import { computed } from 'mobx';
import { Trans } from 'react-i18next';
import { Env } from 'src/core/Environment';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { featureList } from 'src/featureList';
import { AgentBorrowersPage } from 'src/pages/AgentBorrowersPage/AgentBorrowersPage';
import { AgentLoanApplicationsPage } from 'src/pages/AgentLoanApplicationsPage/AgentLoanApplicationsPage';
import { AgentLoansPage } from 'src/pages/AgentLoansPage/AgentLoansPage';
import { AgentOperationsPage } from 'src/pages/AgentOperationsPage/AgentOperationsPage';
import { AgentProfilePage } from 'src/pages/AgentProfilePage/AgentProfilePage';
import { AgentsPage } from 'src/pages/AgentsPage/AgentsPage';
import { BorrowersPage } from 'src/pages/BorrowersPage/BorrowersPage';
import { CampaignsPage } from 'src/pages/CampaignsPage/CampaignsPage';
import { CollectionsPage } from 'src/pages/CollectionsPage/CollectionsPage';
import { FormioSandbox } from 'src/pages/FormioSandbox/FormioSandbox';
import { LeadsPage } from 'src/pages/LeadsPage/LeadsPage';
import { LoanApplicationsPage } from 'src/pages/LoanApplicationsPage/LoanApplicationsPage';
import { LoansPage } from 'src/pages/LoansPage/LoansPage';
import { OperationsPage } from 'src/pages/OperationsPage/OperationsPage';
import { ProductsPage } from 'src/pages/ProductsPage/ProductsPage';
import { TasksPage } from 'src/pages/TasksPage/TasksPage';
import { TransactionsPage } from 'src/pages/TransactionsPage/TransactionsPage';
import { RouteDefinition } from 'src/stores/RouterStore/RouterStore.types';
import { UserStore } from 'src/stores/UserStore/UserStore';

const ifFeatureAvailable = (permissions: boolean[]) => {
    return permissions.some((permission) => Boolean(permission));
};

export class RouterStoreClass {
    @computed get routes(): RouteDefinition[] {
        return [
            {
                key: RoutePaths.tasks,
                path: RoutePaths.tasks,
                Component: TasksPage,
                menuTitle: () => <Trans i18nKey="App.Header.Menu.Tasks" />,
                menuLink: EntityLinks.tasks.list(),
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.tasks,
                    featureList.tasks.unavailable,
                ]),
            },
            {
                key: RoutePaths.loanApplications,
                path: RoutePaths.loanApplications,
                Component: LoanApplicationsPage,
                menuTitle: () => (
                    <Trans i18nKey="App.Header.Menu.Applications" />
                ),
                menuLink: EntityLinks.applications.list(),
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.applications,
                    featureList.applications.unavailable,
                ]),
            },
            {
                key: RoutePaths.agentLoanApplication,
                path: RoutePaths.agentLoanApplication,
                Component: AgentLoanApplicationsPage,
                menuTitle: () => (
                    <Trans i18nKey="App.Header.Menu.AgentApplications" />
                ),
                menuLink: EntityLinks.agentLoanApplications.list(),
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.agentLoanApplications,
                    featureList.agentLoanApplications.unavailable,
                ]),
            },
            {
                key: RoutePaths.operations,
                path: RoutePaths.operations,
                Component: OperationsPage,
                menuLink: EntityLinks.operations.list(),
                menuHide: true,
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.operations,
                    featureList.operations.unavailable,
                ]),
            },
            {
                key: RoutePaths.operationsAgent,
                path: RoutePaths.operationsAgent,
                Component: AgentOperationsPage,
                menuLink: EntityLinks.agentOperations.list(),
                menuHide: true,
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.operationsAgent,
                    featureList.agentOperations.unavailable,
                ]),
            },
            {
                key: RoutePaths.loans,
                path: RoutePaths.loans,
                Component: LoansPage,
                menuTitle: () => <Trans i18nKey="App.Header.Menu.Loans" />,
                menuLink: EntityLinks.loans.list(),
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.loans,
                    featureList.loans.unavailable,
                ]),
            },
            {
                key: RoutePaths.agentLoans,
                path: RoutePaths.agentLoans,
                Component: AgentLoansPage,
                menuTitle: () => <Trans i18nKey="App.Header.Menu.AgentLoans" />,
                menuLink: EntityLinks.agentLoans.list(),
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.agentLoans,
                    featureList.agentLoans.unavailable,
                ]),
            },
            {
                key: RoutePaths.borrowers,
                path: RoutePaths.borrowers,
                Component: BorrowersPage,
                menuTitle: () => <Trans i18nKey="App.Header.Menu.Borrowers" />,
                menuLink: EntityLinks.borrowers.list(),
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.borrowers,
                    featureList.borrowers.unavailable,
                ]),
            },
            {
                key: RoutePaths.products,
                path: RoutePaths.products,
                Component: ProductsPage,
                menuLink: EntityLinks.products.list(),
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.products,
                    featureList.products.unavailable,
                ]),
                menuHide: true,
            },
            {
                key: RoutePaths.campaigns,
                path: RoutePaths.campaigns,
                Component: CampaignsPage,
                menuTitle: () => <Trans i18nKey="App.Header.Menu.Products" />,
                menuLink: EntityLinks.campaigns.list(),
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.products,
                    featureList.products.unavailable,
                ]),
            },
            {
                key: RoutePaths.transactions,
                path: RoutePaths.transactions,
                Component: TransactionsPage,
                menuTitle: () => (
                    <Trans i18nKey="App.Header.Menu.Transactions" />
                ),
                menuLink: EntityLinks.transactions.list(),
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.transactions,
                    featureList.transactions.unavailable,
                ]),
            },
            {
                key: RoutePaths.collections,
                path: RoutePaths.collections,
                Component: CollectionsPage,
                menuTitle: () => (
                    <Trans i18nKey="App.Header.Menu.Collections" />
                ),
                menuLink: EntityLinks.collections.list(),
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.collections,
                    featureList.collections.unavailable,
                ]),
            },
            {
                key: RoutePaths.agents,
                path: RoutePaths.agents,
                Component: AgentsPage,
                menuTitle: () => <Trans i18nKey="App.Header.Menu.Agents" />,
                menuLink: EntityLinks.agents.list(),
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.agents,
                    featureList.agents.unavailable,
                ]),
            },
            {
                key: RoutePaths.formioSandBox,
                path: RoutePaths.formioSandBox,
                Component: FormioSandbox,
                exact: false,
                unavailable: !Env.isDev,
            },
            {
                key: RoutePaths.leads,
                path: RoutePaths.leads,
                Component: LeadsPage,
                menuTitle: () => <Trans i18nKey="App.Header.Menu.Leads" />,
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.leads,
                    featureList.leads.unavailable,
                ]),
            },
            {
                key: RoutePaths.viewProfile,
                path: RoutePaths.viewProfile,
                Component: AgentProfilePage,
                exact: false,
                menuHide: true,
            },
            {
                key: RoutePaths.agentBorrowers,
                path: RoutePaths.agentBorrowers,
                Component: AgentBorrowersPage,
                menuTitle: () => (
                    <Trans i18nKey="App.Header.Menu.AgentBorrowers" />
                ),
                menuLink: EntityLinks.agentBorrowers.list(),
                exact: false,
                unavailable: ifFeatureAvailable([
                    UserStore.canNot.view.agentBorrowers,
                    featureList.agentBorrowers.unavailable,
                ]),
            },
        ];
    }

    @computed get menuItems(): RouteDefinition[] {
        return this.routes
            .filter((route) => route.menuTitle)
            .filter((route) => !route.unavailable)
            .filter((route) => !route.menuHide);
    }
}

export const RouterStore = new RouterStoreClass();
