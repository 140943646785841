import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { EntityStatusInfo } from 'src/components/EntityStatusInfo/EntityStatusInfo';
import {
    MainContainer,
    MainEntityListItemText,
    MasterDetailsAsideItem,
    SecondaryContainer,
    SecondaryEntityListItemText,
} from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { getMaskedPhoneValue } from 'src/formio/utils/getDisabledValue';
import { LegalEntity, Person } from 'src/generated-api-client';
import { BorrowerStatusInfoMap } from 'src/pages/BorrowersPage/BorrowerStatusInfoMap';
import { FullNameHelper } from 'src/utils/FullNameHelper';

type BorrowerListItemProps = { item: LegalEntity | Person };

export const BorrowerListItem = observer(({ item }: BorrowerListItemProps) => {
    const fullName = FullNameHelper(item);

    return (
        <MasterDetailsAsideItem to={EntityLinks.borrowers.itemDetails(item.id)}>
            <MainContainer>
                <Tooltip title={fullName}>
                    <EllipsisContainer>
                        <MainEntityListItemText className="strong-text">{`${item.id} `}</MainEntityListItemText>
                        <MainEntityListItemText>
                            {fullName}
                        </MainEntityListItemText>
                    </EllipsisContainer>
                </Tooltip>
                {item.blacklisted && (
                    <EntityStatusInfo
                        statusInfoMap={BorrowerStatusInfoMap}
                        status={getBorrowerStatus(item.blacklisted)}
                        entityName="borrower"
                    />
                )}
            </MainContainer>
            <SecondaryContainer>
                <Tooltip title={getMaskedPhoneValue(item?.phone || '')}>
                    <EllipsisContainer>
                        <SecondaryEntityListItemText>
                            <span className="strong-text">
                                {getMaskedPhoneValue(item?.phone || '')}
                            </span>
                        </SecondaryEntityListItemText>
                    </EllipsisContainer>
                </Tooltip>
                <Tooltip title={item.email}>
                    <EllipsisContainer>
                        <SecondaryEntityListItemText>
                            {item.email}
                        </SecondaryEntityListItemText>
                    </EllipsisContainer>
                </Tooltip>
            </SecondaryContainer>
        </MasterDetailsAsideItem>
    );
});

const getBorrowerStatus = (status: boolean) => {
    if (status) return 'BLOCKLISTED';
};
