import { ReactComponent as LogoImage } from 'src/icons/logo.svg';
import styled from 'styled-components';

const StyledWrapper = styled.div`
    color: white;
    display: flex;
    width: 22rem;

    svg {
        width: 8rem;
        margin-bottom: 0.5rem;
    }
`;

export const BOLogo = () => {
    return (
        <StyledWrapper>
            <LogoImage />
        </StyledWrapper>
    );
};
