import {
    convertMapToList,
    DEFAULT_STATUS_KEY,
    StatusInfo,
} from 'src/components/EntityStatusInfo/EntityStatusInfo';
import { CssColors } from 'src/styles/CssVariables';

export const AgentBorrowerStatusInfoMap: Record<string, StatusInfo> = {
    BLOCKLISTED: {
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        title: 'Blocklisted',
        backgroundColor: CssColors.mainRed,
    },
    [DEFAULT_STATUS_KEY]: {
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        title: '',
        backgroundColor: CssColors.mainGray,
    },
};

export const getCollectionStatuses = () => {
    return convertMapToList(AgentBorrowerStatusInfoMap);
};
