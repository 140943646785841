import { PhoneNumberUtil } from 'google-libphonenumber';

export const getIsoCodeByPhone = (
    phone: string,
): { countryLetterCode: string; countryDialCode: number } | null => {
    if (!phone || phone.trim().length < 4) {
        console.error('Invalid or too short phone number:', phone);

        return null;
    }

    try {
        const phoneUtil = PhoneNumberUtil.getInstance();

        // Normalize phone number to include a leading "+"
        const normalizedPhone = phone.startsWith('+') ? phone : `+${phone}`;

        const parsedNumber = phoneUtil.parse(normalizedPhone);
        const countryDialCode = parsedNumber.getCountryCode() as number;

        const countryLetterCode =
            phoneUtil.getRegionCodeForCountryCode(countryDialCode);

        return { countryLetterCode, countryDialCode };
    } catch (error) {
        console.error('Error parsing phone number:', error.message || error);

        return null;
    }
};
