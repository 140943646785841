import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { LegalEntity, Person } from 'src/generated-api-client';
import { AgentBorrowerListItem } from 'src/pages/AgentBorrowersPage/AgentBorrowerListItem';
import { AgentBorrowersHeaderPanel } from 'src/pages/AgentBorrowersPage/AgentBorrowersHeaderPanel';
import { AgentBorrowersStore } from 'src/stores/AgentBorrowersStore/AgentBorrowersStore';

const store = AgentBorrowersStore;

export const AgentBorrowersPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="agent-borrowers"
            store={store}
            asidePanel={<AgentBorrowersAsidePanel />}
        >
            <EntityPresentation store={store} entityName="agent-borrower" />
        </MasterDetailsPage>
    );
});

const AgentBorrowersAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: LegalEntity | Person) => {
                return (
                    <List.Item key={item.taxNumber}>
                        <AgentBorrowerListItem item={item} />
                    </List.Item>
                );
            }}
            header={<AgentBorrowersHeaderPanel />}
        />
    );
});
