import { computed } from 'mobx';
import { LegalEntity, Person } from 'src/generated-api-client';
import {
    agentBorrowersApi,
    bpmsResourcesManagerApi,
} from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/BorrowersStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type AgentBorrowersFilter = {
    search?: string;
    blackList?: boolean;
};

type AgentBorrowerItemDetails = LegalEntity | Person;
export class AgentBorrowersStoreClass extends BasicStore<
    AgentBorrowerItemDetails,
    AgentBorrowersFilter
> {
    itemDetailsFormKey = 'embedded:deployment:forms/ap/ap-19-borrower.json';

    pager = new Pager();
    api: BasicStoreApi<AgentBorrowerItemDetails> = {
        loadList: async (...args: any[]) => {
            const options = RequestHelper.getOptionsFromArgs(args);
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                agentBorrowersApi.findAllAgentBorrowers(
                    this.pager?.page,
                    this.pager.size,
                    InitialFilters.defaultSort,
                    this.filterCriteria.filter.blackList,
                    this.filterCriteria.filter.search,
                    options,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },
        loadItemForm: (formKey: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(formKey, options),
            );
        },
        loadItem: (id: number, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                agentBorrowersApi.getAgentBorrower(id, options),
            );
        },
    };

    filterCriteria: FilterCriteria<AgentBorrowersFilter> = new FilterCriteria(
        {},
    );

    @computed get hasFilter() {
        return Boolean(this.filterCriteria.filter.search);
    }
}

export const AgentBorrowersStore = new AgentBorrowersStoreClass();
