import {
    AgentApiFactory,
    AgentBorrowersApiFactory,
    AgentLoanApiFactory,
    AgentLoanApplicationApiFactory,
    AgentPortalApiFactory,
    BorrowerApiFactory,
    BPMSResourcesManagementApiFactory,
    CampaignApiFactory,
    CollectionApiFactory,
    Configuration,
    LeadApiFactory,
    LoanApiFactory,
    LoanApplicationApiFactory,
    LoanProductApiFactory,
    ProcessDefinitionManagerApiFactory,
    TaskManagerApiFactory,
    TransactionApiFactory,
    UISettingsApiFactory,
} from 'src/generated-api-client';

const apiConfig: Configuration | undefined = undefined;
const basePath = '';

const filesApiPath = '/api/file?';
export const getFileUrl = (filename: string) => {
    const searchParams = new URLSearchParams();
    searchParams.append('form', filename);

    return `${filesApiPath}${searchParams.toString()}`;
};

export const processDefinitionManagerApi = ProcessDefinitionManagerApiFactory(
    apiConfig,
    basePath,
);

export const taskManagerApi = TaskManagerApiFactory(apiConfig, basePath);
export const bpmsResourcesManagerApi = BPMSResourcesManagementApiFactory(
    apiConfig,
    basePath,
);

export const uiSettingsApi = UISettingsApiFactory(apiConfig, basePath);

export const loanApplicationsApi = LoanApplicationApiFactory(
    apiConfig,
    basePath,
);

export const loansApi = LoanApiFactory(apiConfig, basePath);
export const productsApi = LoanProductApiFactory(apiConfig, basePath);
export const campaignsApi = CampaignApiFactory(apiConfig, basePath);
export const transactionsApi = TransactionApiFactory(apiConfig, basePath);
export const borrowersApi = BorrowerApiFactory(apiConfig, basePath);
export const collectionsApi = CollectionApiFactory(apiConfig, basePath);
export const agentsApi = AgentApiFactory(apiConfig, basePath);
export const leadsApi = LeadApiFactory(apiConfig, basePath);
export const boLeadsApi = LeadApiFactory(apiConfig, basePath);
export const agentProfileApi = AgentPortalApiFactory(apiConfig, basePath);

export const agentLoanApplicationsApi = AgentLoanApplicationApiFactory(
    apiConfig,
    basePath,
);
export const agentLoansApi = AgentLoanApiFactory(apiConfig, basePath);

export const agentBorrowersApi = AgentBorrowersApiFactory(apiConfig, basePath);
